import styled from 'styled-components';

export const ReadMoreButton = styled.button`
  position: relative;
  display: grid;
  place-items: center;
  margin-top: 20px;
  margin-right: auto;
  margin-left: 0;
  text-decoration: none;
  padding: 0 5px;
  border: none;
  border-radius: 15px 5px 15px 5px;
  background: none;
  cursor: pointer;
  box-shadow: 0 0 38.8px -26px var(--advSvg);
  font-weight: normal;
  text-align: center;
  word-break: keep-all;
  transition: background .3s ease-in, transition .3s ease-in-out;
  transform-origin: left;
  
  &::before {
      position: absolute;
      content: "";
      bottom: 0;
      height: 3px;
      width: 100%;
      background: var(--advSvg);
      transition: height .3s ease-in-out;
  }
  
  &:hover:before {
      height: 5px;
  }
  
  a {
      color: var(--textNormal);
      transition: color .3s ease-in;   
  }
  
  &:hover {
      background: none;
      
      a {
        color: black;
      }
  }
  //
  // &:focus {
  //     box-shadow: ${({ send }) => send ? '0 0 38.8px -5px var(--advSvg)' : 'unset' };
  //     //transform: ${({ send }) => send ? 'scale(1.05)' : 'unset' };
  // }
`;