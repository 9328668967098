import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    margin: 0 auto;
    max-width: 1024px;
    width: ${({ fullWidth }) => fullWidth && '100%' };
    padding: ${({ autoPadding }) => autoPadding && '0 1rem'};
    box-sizing: ${({ autoPadding }) => autoPadding && 'content-box'};
   
  `;

const ContainerComponent = ({ children, noPadding, autoPadding, fullWidth }) => (
    <Container className="container" noPadding={noPadding} fullWidth={fullWidth} autoPadding={autoPadding}>
        {children}
    </Container>
);

export default ContainerComponent;