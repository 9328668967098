import React from "react"
import { useTranslation } from "react-i18next";
import Image from 'gatsby-image'
import { graphql, Link } from "gatsby"
import Container from "../components/Container"
import {
    StyledOl,
    ThumbnailsInformations,
    ThumbnailsExcerpt,
    ThumbnailsIconWrapper,
    ThumbnailsLink,
    ThumbnailsStyledLi
} from "../components/PageBlog"
import DateIcon from "../../static/date.svg"
import TimeIcon from "../../static/time.svg"
import { ReadMoreButton } from "../components/Button/ReadMoreButton"

const CategoryPage = ({ data, pageContext }) => {

const { i18n } = useTranslation();

return (
    <>
        <Container>
            <p>Liczba postów w kategorii <b>{pageContext.category}</b> wynosi: <b>{data.pl.totalCount}</b></p>
            {i18n.language === 'pl' &&
                <>
                    <p style={{ margin: '50px 0 100px', color: 'var(--textTitle)' }}>
                        O sprawach technicznych, w nietechniczny sposób. Rozkładam internet i webdevelopment na części pierwsze.
                    </p>
                    <StyledOl>
                        {data.pl.nodes.map(edge => (
                            <ThumbnailsStyledLi className="blog_post" key={edge.id}>
                                <ThumbnailsLink to={`/blog/${edge.fields.slug}` }>
                                    {/*<Image fluid={edge.frontmatter.featuredImage.childImageSharp.fluid}/>*/}
                                    <ThumbnailsInformations>
                                        <h2>{edge.frontmatter.title}</h2>
                                        <ThumbnailsExcerpt>
                                            {edge.excerpt}
                                        </ThumbnailsExcerpt>
                                        <ThumbnailsIconWrapper>
                                            <DateIcon/>
                                            <span>{edge.frontmatter.date}</span>
                                            <TimeIcon/>
                                            <span>{edge.timeToRead} min czytania</span>
                                        </ThumbnailsIconWrapper>
                                    </ThumbnailsInformations>
                                </ThumbnailsLink>
                            </ThumbnailsStyledLi>
                        ))}
                    </StyledOl>
                </>
            }
            {i18n.language === 'en' &&
                <>
                    <p style={{ margin: '50px 0 100px'}}>About technical stuff, in non-technical way. I </p>
                    <ol>
                    {data.en.nodes.map(node => {
                    return (
                    <ThumbnailsStyledLi key={node.id}>
                    <Image fluid={node.frontmatter.featuredImage.childImageSharp.fluid}/>
                    <ThumbnailsInformations>
                    <Link to={`/blog/${node.fields.slug}`}>
                    <h2>{node.frontmatter.title}</h2>
                    </Link>
                    <ThumbnailsExcerpt>{node.excerpt}</ThumbnailsExcerpt>
                    <ThumbnailsIconWrapper>
                    <DateIcon/>
                    <span><p>{node.frontmatter.date}</p></span>
                    <TimeIcon/>
                    <span><p>{node.timeToRead} min czytania</p></span>
                    </ThumbnailsIconWrapper>
                    <ReadMoreButton filled>
                    <Link to={`/blog/${node.fields.slug}`}>
                    więcej
                    </Link>
                    </ReadMoreButton>
                    </ThumbnailsInformations>
                    </ThumbnailsStyledLi>
                    )
                    })}
                    </ol>
                </>
            }
        </Container>
    </>
)};

export default CategoryPage;

export const query = graphql`
    query CategoryPageQuery($category: String) {
        pl: allMdx (
            filter: { frontmatter: { category: { eq: $category } lang: { eq: "pl" } } }
            sort: { fields: frontmatter___date, order: DESC }
        ) {
            totalCount
            nodes {
                timeToRead
                excerpt(pruneLength: 200)
                frontmatter {
                    date
                    title
                    lang
                    category
                    featuredImage {
                        childImageSharp {
                            fluid(maxWidth: 350, maxHeight: 300) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                fields {
                    slug
                }
                id
            }
        }
        en: allMdx (
            filter: { frontmatter: { category: { eq: $category } lang: { eq: "en" } } }
            sort: { fields: frontmatter___date, order: DESC }
        ) {
            totalCount
            nodes {
                timeToRead
                excerpt(pruneLength: 200)
                frontmatter {
                    date
                    title
                    lang
                    category
                    featuredImage {
                        childImageSharp {
                            fluid(maxWidth: 350, maxHeight: 300) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                fields {
                    slug
                }
                id
            }
        }
    }
`;